import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const COLORS = {
  black: '#000000',
  blackLight: '#1D2023',
  brightBlue: '#2F7EF6',
  brightOrange: '#FAC031',
  greyLight: '#F2F3F7',
  lightGrayishBlue: '#DEE1E8',
  dirtyBlue: '#626C77',
  grayishBlue: '#969FA8',
  green: '#26CD58',
  greenLight: '#12B23F',
  mostlyWhite: '#FAFAFA',
  pureBlue: '#007CFF',
  red: '#E30611',
  vividOrange: '#EB4A13',
  redOrange: '#F95721',
  silver: '#C4C4C4',
  veryDarkBlue: '#0A0743',
  white: '#FFFFFF',
  lightGrey: '#BCC3D080',
};

const defaultTheme = createMuiTheme({
  breakpoints: {
    desktopXL: 1921,
    desktopL: 1681,
    desktopM: 1441,
    desktopS: 1281,
    desktopXS: 961,
    tabletVertical: 769,
    phoneLarge: 560,
    phoneSmall: 360,
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1300,
      xl: 1440,
    },
  },
  palette: {
    colors: {
      white: COLORS.white,
      mostlyWhite: COLORS.mostlyWhite,
      black: COLORS.black,
      blackLight: COLORS.blackLight,
      brightBlue: COLORS.brightBlue,
      red: COLORS.red,
      vividOrange: COLORS.vividOrange,
      green: COLORS.green,
      greenLight: COLORS.greenLight,
      dirtyBlue: COLORS.dirtyBlue,
      pureBlue: COLORS.pureBlue,
      redOrange: COLORS.redOrange,
      greyLight: COLORS.greyLight,
      lightGrayishBlue: COLORS.lightGrayishBlue,
      silver: COLORS.silver,
      brightOrange: COLORS.brightOrange,
      grayishBlue: COLORS.grayishBlue,
      veryDarkBlue: COLORS.veryDarkBlue,
      lightGrey: COLORS.lightGrey,
    },
  },
  shadowing: {
    boxShadow: '0em 6.25em 5em rgba(0,0,0,0.07),0em 2.6111em 2.088em rgba(0,0,0,0.0503198),0em 1.396em 1.116em rgba(0,0,0,0.0417275),0em 0.782em 0.626em rgba(0,0,0,0.035),0em 0.415em 0.332em rgba(0,0,0,0.0282725),0em 0.172em 0.138em rgba(0,0,0,0.0196802)',
  },
});
const { breakpoints } = defaultTheme;

const theme = {
  ...defaultTheme,
  overrides: {
    MuiSkeleton: {
      root: {
        backgroundColor: COLORS.greyLight,
      },
    },
    MuiContainer: {
      root: {
        [breakpoints.up('lg')]: {
          paddingLeft: '88px',
          paddingRight: '88px',
        },
        [breakpoints.up('sm')]: {
          paddingLeft: '40px',
          paddingRight: '40px',
        },
      },
    },
    MuiTypography: {
      fontFamily: '"MTSSans",sans-serif',
      h1: {
        fontFamily: '"MTSSans Wide",sans-serif',
        fontWeight: 700,
        fontSize: 44,
        lineHeight: '44px',
        color: COLORS.blackLight,
        textTransform: 'uppercase',
      },
      h2: {
        fontFamily: '"MTSSans Black",sans-serif',
        fontWeight: 800,
        lineHeight: '32px',
        color: COLORS.blackLight,
        fontSize: '32px',
        [breakpoints.down('md')]: {
          fontSize: '30px',
        },
        [breakpoints.down('sm')]: {
          fontSize: '24px',
        },
      },
      h3: {
        fontFamily: '"MTSSans",sans-serif',
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '28px',
        color: COLORS.black,
      },
      h4: {
        fontFamily: '"MTSSans",sans-serif',
        fontWeight: 500,
        fontSize: 22,
        lineHeight: '28px',
        color: COLORS.black,
        [breakpoints.down('xs')]: {
          fontSize: '17px',
        },
      },
      h6: {
        fontFamily: '"MTSSans",sans-serif',
        fontWeight: 600,
        fontSize: 20,
        lineHeight: '24px',
        color: COLORS.black,
      },
      body1: {
        fontFamily: '"MTSSans",sans-serif',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '24px',
        color: COLORS.black,
      },
      body2: {
        fontFamily: '"MTSSans",sans-serif',
        fontWeight: 'normal',
        fontSize: 17,
        lineHeight: '24px',
        color: COLORS.blackLight,
      },
    },
  },
  // typography: {
  //   fontFamily: '"MTSSans",sans-serif',
  //   h1: {
  //     fontFamily: '"MTSSans Wide",sans-serif',
  //     fontWeight: 700,
  //     fontSize: 44,
  //     lineHeight: '44px',
  //     color: COLORS.blackLight,
  //     textTransform: 'uppercase',
  //   },
  //   h2: {
  //     fontFamily: '"MTSSans Black",sans-serif',
  //     fontWeight: 800,
  //     fontSize: 32,
  //     lineHeight: '28px',
  //     color: COLORS.blackLight,
  //     [breakpoints.down.desktopM]: {
  //       fontSize: '30px',
  //     },
  //   },
  //   h3: {
  //     fontFamily: '"MTSSans",sans-serif',
  //     fontWeight: 500,
  //     fontSize: 24,
  //     lineHeight: '28px',
  //     color: COLORS.black,
  //   },
  //   h6: {
  //     fontFamily: '"MTSSans",sans-serif',
  //     fontWeight: 600,
  //     fontSize: 20,
  //     lineHeight: '24px',
  //     color: COLORS.black,
  //   },
  //   body1: {
  //     fontFamily: '"MTSSans",sans-serif',
  //     fontWeight: 500,
  //     fontSize: 20,
  //     lineHeight: '24px',
  //     color: COLORS.black,
  //   },
  //   body2: {
  //     fontFamily: '"MTSSans",sans-serif',
  //     fontWeight: 'normal',
  //     fontSize: 17,
  //     lineHeight: '24px',
  //     color: COLORS.blackLight,
  //   },
  // },

};

export default createMuiTheme(theme);
