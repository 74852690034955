import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

const countSelector = ((state) => state.forum.commentsCount);
const messagesSelector = ((state) => state.forum.comments);

function* getMessagesSaga({
  offset = 0,
}) {
  try {
    const { data: response } = yield call(request,
      {
        pathname: '/messages',
        query: `?sort_by=created_at&order_by=desc&limit=15&offset=${offset}`,
      });

    const commentsCount = yield select(countSelector);
    const comments = yield select(messagesSelector);
    const hasNewMessages = (offset === 0
      && commentsCount !== response.overall_count)
      || (offset !== 0 && comments.length !== commentsCount);

    if (hasNewMessages) {
      if (response.messages) {
        const { messages } = response;
        yield put({ type: actionTypes.SHORT_USERS_INFO_REQUESTED, messages });
      }
    }

    yield put({ type: actionTypes.MESSAGES_LOADED, response, offset });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.MESSAGES_REQUESTED, getMessagesSaga);
}
