import {
  all,
  call,
} from 'redux-saga/effects';

import checkSingersRegisterStatus from './checkSingersRegisterStatus';
import createTemplate from './createTemplate';
import documentSign from './documentSign';
import downloadDocument from './downloadDocument';
import formationSingersRegister from './formationSingersRegister';
import generateOfferSms from './generateOfferSms';
import getSingersRegister from './getSingersRegister';

export default function* formSaga() {
  yield all([
    call(checkSingersRegisterStatus),
    call(createTemplate),
    call(documentSign),
    call(downloadDocument),
    call(formationSingersRegister),
    call(generateOfferSms),
    call(getSingersRegister),
  ]);
}
