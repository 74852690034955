import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';

import appState from './appState';
import login from './login';
import general from './general';
import payments from './payments';
import projects from './projects';
import speakers from './speakers';
import forum from './forum';
import documents from './documents';
import statistics from './statistics';
import users from './users';

export default combineReducers({
  appState,
  login,
  general,
  payments,
  projects,
  speakers,
  forum,
  documents,
  statistics,
  users,
  routing,
});
