import { memo } from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';

import useQuery from '../useQuery';
import history from '../../history';
import {
  getCurrentUser,
  loginSuccess,
} from '../../redux/actions';

const QueryParser = ({
  location: {
    search,
    pathname,
  },
}) => {
  const dispatch = useDispatch();

  const {
    token,
    error,
    callbackUrl,
  } = useQuery(search);

  if (token) {
    localStorage.setItem('token', token);
    history.push(pathname);
    dispatch(loginSuccess());
    dispatch(getCurrentUser());
  }

  if (error) {
    history.push('/error');
  }

  if (callbackUrl) {
    history.push('/');
  }

  return null;
};

export default withRouter(memo(QueryParser));
