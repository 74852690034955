import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';
import rootSaga from '../sagas';
import history from '../../history';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initializeSagaMiddleware = createSagaMiddleware();
const initializeRouterMiddleware = routerMiddleware(history);

// eslint-disable-next-line no-underscore-dangle
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    persistedReducer,
    storeEnhancers(
      applyMiddleware(initializeSagaMiddleware, initializeRouterMiddleware),
    ),
  );

  initializeSagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
};
