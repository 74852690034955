import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

const token = localStorage.getItem('token') || '';

const initialState = {
  isLogged: Boolean(token),
  loginUrl: '',
  loginLoading: false,
};

function login(state = initialState, action) {
  const { response } = action;
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.LOGIN_REQUESTED:
      return {
        ...state,
        loginLoading: true,
        loginUrl: initialState.loginUrl,
      };
    case actionTypes.LOGIN_LINK_ACQUIRED:
      return {
        ...state,
        loginLoading: false,
        loginUrl: response,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLogged: false,
      };

    default:
      return state;
  }
}

export default login;
