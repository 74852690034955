import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

import changeUDRFormat from '../../helpers/changeUDRFormat';
import changeUserFormat from '../../helpers/changeUserFormat';
import changeHistoryFormat from '../../helpers/changeHistoryFormat';

const initialState = {
  isUsedUDR: false,
  loading: false,
  operationsHistory: [],
  shortUsersInfo: [],
  user: null,
  userUDRForProject: [],
  userPostUDRForProject: [],
  userRefuseTransaction: false,
};

function users(state = initialState, action) {
  const { response } = action;
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.CURRENT_USER_LOADED:
      return {
        ...state,
        user: changeUserFormat(response),
      };

    case actionTypes.SHORT_USERS_INFO_LOADED:
      return {
        ...state,
        shortUsersInfo: [...state.shortUsersInfo, ...response],
      };
    case actionTypes.USER_UDR_CONVERTED:
      return {
        ...state,
        userUDRForProject: changeUDRFormat(response),
      };
    case actionTypes.REFUSE_TRANSACTION_ACCEPTED:
      return {
        ...state,
        userRefuseTransaction: response,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
      };
    case actionTypes.USER_HISTORY_LOADED:
      return {
        ...state,
        loading: false,
        operationsHistory: changeHistoryFormat(response),
      };
    case actionTypes.USER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        operationsHistory: initialState.operationsHistory,
      };
    default:
      return state;
  }
}

export default users;
