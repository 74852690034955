import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* createSMSsignature({ bucketId, code }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/payments/sms_sign',
        data: {
          bucket_uuid: bucketId,
          sms: code,
        },
      });

    if (response.status === 'PAYMENT') {
      yield put({ type: actionTypes.INVEST_DOC_SINGED, response });
      yield put({ type: actionTypes.GENERATE_PAYMENT_PLUGIN_REQUEST, bucketId });
    }
  } catch ({ errors }) {
    yield put({ type: actionTypes.ERROR_CODE, errors });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SMS_SING_REQUEST, createSMSsignature);
}
