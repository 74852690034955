import {
  takeLeading,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* loginSaga({
  pathname,
}) {
  try {
    const { data: response } = yield call(request,
      {
        pathname: '/login',
        headers: {
          'X-Redirect-Url': pathname,
        },
      });

    document.location.href = response.url;
    // window.location.assign(`${response.url}`);

    yield put({ type: actionTypes.LOGIN_LINK_ACQUIRED, response });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeLeading(actionTypes.LOGIN_REQUESTED, loginSaga);
}
