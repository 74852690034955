import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    maxWidth: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    paddingTop: '54px',
    // overflow: 'auto',//todo dont change!
  },
}));

export default useStyles;
