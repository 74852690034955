import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* generateOfferSms({ documentsList }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/documents/accept',
        data: { document_uuids: documentsList },
      });

    if (response.success) {
      yield put({
        type: actionTypes.SMS_MODAL_TOGGLED,
        value: true,
        modalType: 'offer',
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SMS_GENERATE_REQUESTED, generateOfferSms);
}
