import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* resendSms({ bucketId }) {
  try {
    yield call(request,
      {
        method: 'POST',
        pathname: '/payments/resend_sms',
        data: {
          bucket_uuid: bucketId,
        },
      });

    yield put({ type: actionTypes.SMS_SIGN });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SMS_RESEND_REQUEST, resendSms);
}
