import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* sendInvoiceDetails({ projectId, value: details }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: `/payments/project/${projectId}/refund_info`,
        data: {
          bik: details['БИК'],
          bank_name: details['Название банка'],
          bank_account: details['Номер счета'],
        },
      });

    yield put({ type: actionTypes.INVOICE_DETAILS_SEND_SUCCESS, response });

    yield put({
      type: actionTypes.INFO_MODAL_OPEN,
      messageTitle: 'Мы получили ваши реквизиты',
      message: 'Ожидайте перевода от банка',
    });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.INVOICE_DETAILS_SEND_REQUESTED, sendInvoiceDetails);
}
