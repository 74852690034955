const changeHistoryFormat = (
  history,
) => (history.map((transaction) => ({
  transactionId: transaction.transaction_uuid,
  transactionDate: transaction.date,
  sum: transaction.sum / 100,
  status: transaction.status,
  type: transaction.type,
  projectInfo: transaction.project_info,
})));

export default changeHistoryFormat;
