import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

const idSelector = ((state) => state.projects.currentProject.id);
const modalTypeSelector = ((state) => state.appState.agreementsModalType);

function* getCurrentUserSaga() {
  try {
    const { data: response } = yield call(request,
      {
        pathname: '/me',
      });

    const agreementsModalType = yield select(modalTypeSelector);
    const projectId = yield select(idSelector);
    yield put({ type: actionTypes.CURRENT_USER_LOADED, response });
    if (
      response.role === 'Investor'
      && agreementsModalType === 'invest'
      && projectId?.length
    ) {
      yield put({ type: actionTypes.INVEST_REQUESTED, projectId });
    }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.CURRENT_USER_REQUESTED, getCurrentUserSaga);
}
