const { useLocation } = require('react-router');

const useQuery = (
  path,
) => {
  const locationParams = new URLSearchParams(useLocation().search);
  const params = new URLSearchParams(path);
  const currentParams = path ? params : locationParams;
  return Array.from(currentParams).reduce((result, [key, value]) => ({
    ...result,
    [key]: value,
  }), {});
};

export default useQuery;
