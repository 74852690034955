import * as actionTypes from './actionList';

export const loginAction = (pathname) => ({
  type: actionTypes.LOGIN_REQUESTED,
  pathname,
});

export const logoutAction = () => ({
  type: actionTypes.LOGOUT_REQUESTED,
});

export const toggleMobileMenu = (payload) => ({
  type: actionTypes.MOBILE_MENU_TOGGLED,
  payload,
});

export const toggleInvestmentModal = (value) => ({
  type: actionTypes.INVESTMENT_MODAL_TOGGLED,
  value,
});

export const toggleUDRModal = (value) => ({
  type: actionTypes.UDR_MODAL_TOGGLE,
  value,
});

export const toggleLoadFileModal = (value) => ({
  type: actionTypes.LOAD_FILE_MODAL_TOGGLE,
  value,
});

export const toggleRefuseModal = (value) => ({
  type: actionTypes.REFUSE_MODAL_TOGGLE,
  value,
});

export const toggleTopUpModal = (value) => ({
  type: actionTypes.TOP_UP_MODAL_TOGGLE,
  value,
});

export const toggleModalContacts = (value) => ({
  type: actionTypes.MODAL_CONTACT_TOGGLE,
  value,
});

export const toggleAgreementsModal = (value, modalType) => ({
  type: actionTypes.AGREEMENTS_MODAL_TOGGLED,
  value,
  modalType,
});

export const togglePaymentResultModal = (value, paymentType, message) => ({
  type: actionTypes.PAYMENT_RESULT_MODAL_TOGGLED,
  value,
  paymentType,
  message,
});

export const togglePaymentDetailsModal = (value) => ({
  type: actionTypes.PAYMENT_DETAILS_MODAL_TOGGLED,
  value,
});

export const openInfoModal = (messageTitle, message, infoModalLoader) => ({
  type: actionTypes.INFO_MODAL_OPEN,
  messageTitle,
  message,
  infoModalLoader,
});

export const closeInfoModal = () => ({
  type: actionTypes.INFO_MODAL_CLOSED,
});

export const toggleDisclaimerModal = (value) => ({
  type: actionTypes.DISCLAIMER_MODAL_TOGGLE,
  value,
});

export const toggleSmsModal = (value, modalType, bucketId) => ({
  type: actionTypes.SMS_MODAL_TOGGLED,
  value,
  modalType,
  bucketId,
});

export const toggleAttentionModal = (value, attentionModalType, modalProps) => ({
  type: actionTypes.ATTENTION_MODAL_TOGGLED,
  value,
  attentionModalType,
  modalProps,
});

export const togglePaymentModal = (value) => ({
  type: actionTypes.PAYMENT_MODAL_TOGGLED,
  value,
});

export const toggleGalleryModal = (value, galleryType) => ({
  type: actionTypes.GALLERY_MODAL_TOGGLED,
  value,
  galleryType,
});

export const toggleWithdrawalModal = (value) => ({
  type: actionTypes.WITHDRAWAL_MODAL_TOGGLE,
  value,
});

export const sendInvoiceDetails = (projectId, value) => ({
  type: actionTypes.INVOICE_DETAILS_SEND_REQUESTED,
  projectId,
  value,
});

export const getProjects = () => ({
  type: actionTypes.PROJECTS_REQUESTED,
});

export const getStarProjects = (id) => ({
  type: actionTypes.STAR_PROJECTS_REQUESTED,
  id,
});

export const getOneOfProjects = (id) => ({
  type: actionTypes.ONE_OF_PROJECTS_REQUESTED,
  id,
});

export const getRecentProjects = () => ({
  type: actionTypes.RECENT_PROJECTS_REQUESTED,
});

export const getAvailableInvestments = (projectId) => ({
  type: actionTypes.AVAILABLE_INVESTMENTS_REQUESTED,
  projectId,
});

export const getProjectById = (id) => ({
  type: actionTypes.PROJECT_BY_ID_REQUESTED,
  id,
});

export const getCurrentUser = () => ({
  type: actionTypes.CURRENT_USER_REQUESTED,
});

export const getCurrentSpeaker = () => ({
  type: actionTypes.CURRENT_SPEAKER_REQUESTED,
});

export const getUserProjects = (status) => ({
  type: actionTypes.USER_PROJECTS_REQUESTED,
  status,
});

export const getSpeakerProjects = () => ({
  type: actionTypes.SPEAKER_PROJECTS_REQUESTED,
});

export const getUserDocuments = () => ({
  type: actionTypes.USER_CONTRACTS_REQUESTED,
});

export const getStatistics = (id) => ({
  type: actionTypes.PROJECT_STATISTICS_REQUESTED,
  id,
});

export const downloadDocument = (id, name) => ({
  type: actionTypes.DOWNLOAD_DOCUMENTS_REQUESTED,
  id,
  name,
});

export const getShortUserInfo = () => ({
  type: actionTypes.SHORT_USERS_INFO_REQUESTED,
});

export const getMessages = (offset) => ({
  type: actionTypes.MESSAGES_REQUESTED,
  offset,
});

export const changeInvestAmount = (value) => ({
  type: actionTypes.INVEST_AMOUNT_CHANGED,
  value,
});

export const leaveCommentReplyAction = (text) => ({
  type: actionTypes.COMMENT_REPLY_SENT,
  text,
});

export const resetTimerAction = () => ({
  type: actionTypes.RESET_TIMER,
});

export const loginSuccess = () => ({
  type: actionTypes.LOGIN_SUCCESS,
});

export const convertUserUDR = (udrId) => ({
  type: actionTypes.USER_UDR_CONVERT_REQUEST,
  udrId,
});

export const postRefuseTransaction = (transactionsList) => ({
  type: actionTypes.REFUSE_TRANSACTION_SENT,
  transactionsList,
});

export const documentsSign = (documentsList, code) => ({
  type: actionTypes.DOCUMENTS_SING_REQUESTED,
  documentsList,
  code,
});

export const generateOfferSms = (documentsList) => ({
  type: actionTypes.SMS_GENERATE_REQUESTED,
  documentsList,
});

export const getUserInvestments = (projectsIdList) => ({
  type: actionTypes.USER_INVESTMENTS_REQUESTED,
  projectsIdList,
});

export const sendSmsCode = (bucketId, code) => ({
  type: actionTypes.SMS_SING_REQUEST,
  bucketId,
  code,
});

export const resendSms = (bucketId) => ({
  type: actionTypes.SMS_RESEND_REQUEST,
  bucketId,
});

export const investmentReservation = (reserve) => ({
  type: actionTypes.RESERVATION_REQUESTED,
  reserve,
});

export const createInvestAction = (projectId, redirectPath, reserve) => ({
  type: actionTypes.INVEST_REQUESTED,
  projectId,
  redirectPath,
  reserve,
});

export const createTemplate = (templateType) => ({
  type: actionTypes.TEMPLATE_REQUESTED,
  templateType,
});

export const getUserHistory = (transactionType) => ({
  type: actionTypes.USER_HISTORY_REQUEST,
  transactionType,
});

export const savePostedDate = (postedDate) => ({
  type: actionTypes.POSTED_DATE_SENT,
  postedDate,
});

export const saveStarProjectId = (projectId) => ({
  type: actionTypes.STAR_PROJECT_ID_SAVED,
  projectId,
});

export const generatePaymentPlugin = (bucketId) => ({
  type: actionTypes.GENERATE_PAYMENT_PLUGIN_REQUEST,
  bucketId,
});

export const cancelBucket = (bucketId, cancelStatus) => ({
  type: actionTypes.CANCEL_BUCKET_REQUEST,
  bucketId,
  cancelStatus,
});

export const getWithdrawalData = (projectId) => ({
  type: actionTypes.WITHDRAWAL_DATA_REQUESTED,
  projectId,
});

export const saveCurrentInvestAmount = (projectId, value) => ({
  type: actionTypes.INVEST_AMOUNT_SAVE_REQUESTED,
  projectId,
  value,
});

export const formationRegister = (projectId) => ({
  type: actionTypes.SINGERS_REGISTER_FORMATION_REQUESTED,
  projectId,
});

export const checkSingersRegisterStatus = (taskId) => ({
  type: actionTypes.SINGERS_REGISTER_STATUS_REQUESTED,
  taskId,
});

export const getGeneralInformation = () => ({
  type: actionTypes.GENERAL_INFORMATION_REQUESTED,
});

export const saveGeneralInformation = (response) => ({
  type: actionTypes.GENERAL_INFORMATION,
  response,
});
