import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* createTemplateSaga({
  templateType,
}) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/templates',
        data: {
          type: templateType,
        },
      });

    const documentType = templateType === 'rules' ? 'rules' : 'politics';

    yield put({ type: actionTypes.TEMPLATE_GENERATED, response, documentType });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.TEMPLATE_REQUESTED, createTemplateSaga);
}
