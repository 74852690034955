import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

const initialState = {
  investAmount: '',
  paymentType: '',
  errorText: '',
  refillStatus: false,
  code: '',
  codeExpiryTimestamp: null,
  timerIsVisible: false,
  bucketId: '',
  paymentStatus: '',
  pendingCodeCheck: false,
  redirectPath: '',
  modalProps: {},
  reserve: {},
  withdrawalData: {},
};

function payments(state = initialState, action) {
  const {
    paymentType,
    response,
    value,
    redirectPath,
    message,
    bucketId,
    modalProps,
    withdrawalData,
  } = action;
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.ATTENTION_MODAL_TOGGLED:
      return {
        ...state,
        modalProps,
      };
    case actionTypes.INVEST_AMOUNT_CHANGED:
      return {
        ...state,
        investAmount: value,
      };
    case actionTypes.INVEST_REQUESTED:
      return {
        ...state,
        paymentStatus: initialState.paymentStatus,
        redirectPath,
      };
    case actionTypes.CANCEL_BUCKET_SUCCESS:
      return {
        ...state,
        paymentStatus: initialState.paymentStatus,
        redirectPath: '',
      };
    case actionTypes.PAYMENT_BUCKET_RECEIVED:
      return {
        ...state,
        bucketId: response.bucket_uuid,
        paymentStatus: response.status,
      };
    case actionTypes.SMS_SIGN:
      return {
        ...state,
        codeExpiryTimestamp: new Date().setSeconds(new Date().getSeconds() + 30),
        timerIsVisible: true,
        paymentStatus: response.status,
      };
    case actionTypes.SMS_GENERATE_REQUESTED:
    case actionTypes.SMS_RESEND_REQUEST:
      return {
        ...state,
        codeExpiryTimestamp: new Date().setSeconds(new Date().getSeconds() + 30),
        timerIsVisible: true,
        paymentStatus: '',
      };
    case actionTypes.RESERVATION_REQUESTED:
      return {
        ...state,
        reserve: initialState.reserve,
      };
    case actionTypes.RESET_TIMER:
      return {
        ...state,
        codeExpiryTimestamp: null,
        timerIsVisible: false,
      };
    case actionTypes.SMS_MODAL_TOGGLED:
      return {
        ...state,
        code: '',
        bucketId,
      };
    case actionTypes.SMS_SING_REQUEST:
      return {
        ...state,
        pendingCodeCheck: true,
        paymentStatus: '',
      };
    case actionTypes.INVEST_DOC_SINGED:
    case actionTypes.DOCUMENTS_SINGED:
      return {
        ...state,
        codeExpiryTimestamp: null,
        timerIsVisible: false,
        pendingCodeCheck: false,
        paymentStatus: response.status,
      };
    case actionTypes.ERROR_CODE:
      return {
        ...state,
        codeExpiryTimestamp: null,
        timerIsVisible: false,
        paymentStatus: 'error',
        pendingCodeCheck: false,
      };
    case actionTypes.PAYMENT_RESULT_MODAL_TOGGLED:
      return {
        ...state,
        paymentStatus: '',
        paymentType,
        errorText: message,
      };
    case actionTypes.PAYMENT_PLUGIN_LINK_ACQUIRED:
      return {
        ...state,
        paymentStatus: response.status,
      };
    case actionTypes.WITHDRAWAL_DATA_LOADED:
      return {
        ...state,
        withdrawalData,
      };

    default:
      return state;
  }
}

export default payments;
