import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';
import history from './history';

import App from './App';
import './App.css';

import '@mts-ds/base/build/styles/main.css';

document.documentElement.classList.add('mts-theme', 'mts-theme-light');

const isInternetExplorer = window.navigator.userAgent.indexOf('MSIE ') > -1
  || window.navigator.userAgent.indexOf('Trident/') > -1;

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        {isInternetExplorer
          ? (
            <div className="IE">
              <h1>Для продолжения просмотра сайта установите современный браузер</h1>
            </div>
          )
          : <App />}
      </Router>
    </PersistGate>
  </Provider>,

  document.getElementById('root'),
);
