const changeUserFormat = (
  user,
) => ({
  src: user.picture,
  id: user.uuid,
  role: user.role,
  personId: user.person_id,
  phone: user.phone,
  email: user.email,
  firstName: user.given_name,
  lastName: user.last_name,
  fullName: `${user.given_name} ${user.last_name}`,
});

export default changeUserFormat;
