import {
  all,
  call,
} from 'redux-saga/effects';

import getStarProjects from './getStarProjects';
import saveInvestAmount from './saveInvestAmount';

export default function* formSaga() {
  yield all([
    call(getStarProjects),
    call(saveInvestAmount),
  ]);
}
