import { all, call } from 'redux-saga/effects';

import authSaga from './AuthSaga';
import documentSaga from './DocumentSaga';
import forumSaga from './ForumSaga';
import paymentSaga from './PaymentSaga';
import projectSaga from './ProjectSaga';
import userSaga from './UserSaga';
import speakerSaga from './SpeakerSaga';
import generalSaga from './GeneralSaga';
// import errorSaga from './ErrorSaga';

export default function* rootSaga() {
  yield all([
    call(authSaga),
    call(documentSaga),
    call(forumSaga),
    call(paymentSaga),
    call(projectSaga),
    call(userSaga),
    call(speakerSaga),
    call(generalSaga),
    // call(errorSaga),
  ]);
}
