import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getRecentProjects() {
  try {
    const { data: response } = yield call(request,
      {
        pathname: '/projects',
        query: '?limit=5&offset=0&sort_by=created_at&order_by=desc',
      });

    yield put({ type: actionTypes.RECENT_PROJECTS_LOADED, response });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.RECENT_PROJECTS_REQUESTED, getRecentProjects);
}
