export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_LINK_ACQUIRED = 'LOGIN_LINK_ACQUIRED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const SOCIAL_LINKS_LOADED = 'SOCIAL_LINKS_LOADED';

export const MOBILE_MENU_TOGGLED = 'MOBILE_MENU_TOGGLED';
export const INVESTMENT_MODAL_TOGGLED = 'INVESTMENT_MODAL_TOGGLED';
export const AGREEMENTS_MODAL_TOGGLED = 'AGREEMENTS_MODAL_TOGGLED';
export const PAYMENT_MODAL_TOGGLED = 'PAYMENT_MODAL_TOGGLED';

export const INFO_MODAL_OPEN = 'INFO_MODAL_OPEN';
export const INFO_MODAL_CLOSED = 'INFO_MODAL_CLOSED';

export const ATTENTION_MODAL_TOGGLED = 'ATTENTION_MODAL_TOGGLED';
export const GALLERY_MODAL_TOGGLED = 'GALLERY_MODAL_TOGGLED';
export const SMS_MODAL_TOGGLED = 'SMS_MODAL_TOGGLED';

export const UDR_MODAL_TOGGLE = 'UDR_MODAL_TOGGLE';
export const LOAD_FILE_MODAL_TOGGLE = 'LOAD_FILE_MODAL_TOGGLE';
export const REFUSE_MODAL_TOGGLE = 'REFUSE_MODAL_TOGGLE';
export const TOP_UP_MODAL_TOGGLE = 'TOP_UP_MODAL_TOGGLE';
export const MODAL_CONTACT_TOGGLE = 'MODAL_CONTACT_TOGGLE';
export const DISCLAIMER_MODAL_TOGGLE = 'DISCLAIMER_MODAL_TOGGLE';
export const WITHDRAWAL_MODAL_TOGGLE = 'WITHDRAWAL_MODAL_TOGGLE';

export const PAYMENT_RESULT_MODAL_TOGGLED = 'PAYMENT_RESULT_MODAL_TOGGLED';
export const PAYMENT_DETAILS_MODAL_TOGGLED = 'PAYMENT_DETAILS_MODAL_TOGGLED';

export const ERROR_OCCURRED = 'ERROR_OCCURRED';

export const PROJECTS_REQUESTED = 'PROJECTS_REQUESTED';
export const PROJECTS_LOADED = 'PROJECTS_LOADED';

export const ONE_OF_PROJECTS_REQUESTED = 'ONE_OF_PROJECTS_REQUESTED';

export const RECENT_PROJECTS_REQUESTED = 'RECENT_PROJECTS_REQUESTED';
export const RECENT_PROJECTS_LOADED = 'RECENT_PROJECTS_LOADED';

export const SPEAKER_PROJECTS_REQUESTED = 'SPEAKER_PROJECTS_REQUESTED';
export const SPEAKER_PROJECTS_LOADED = 'SPEAKER_PROJECTS_LOADED';

export const AVAILABLE_INVESTMENTS_REQUESTED = 'AVAILABLE_INVESTMENTS_REQUESTED';
export const AVAILABLE_INVESTMENTS_LOADED = 'AVAILABLE_INVESTMENTS_LOADED';

export const USER_INVESTMENTS_REQUESTED = 'USER_INVESTMENTS_REQUESTED';
export const USER_INVESTMENTS_LOADED = 'USER_INVESTMENTS_LOADED';
export const USER_INVESTMENTS_ERROR = 'USER_INVESTMENTS_ERROR';

export const PROJECT_BY_ID_REQUESTED = 'PROJECT_BY_ID_REQUESTED';
export const CURRENT_PROJECT_LOADED = 'CURRENT_PROJECT_LOADED';

export const CURRENT_USER_REQUESTED = 'CURRENT_USER_REQUESTED';
export const CURRENT_USER_LOADED = 'CURRENT_USER_LOADED';

export const CURRENT_SPEAKER_REQUESTED = 'CURRENT_SPEAKER_REQUESTED';
export const CURRENT_SPEAKER_LOADED = 'CURRENT_SPEAKER_LOADED';

export const USER_PROJECTS_REQUESTED = 'USER_PROJECTS_REQUESTED';
export const USER_PROJECTS_LOADED = 'USER_PROJECTS_LOADED';

export const STAR_PROJECTS_REQUESTED = 'STAR_PROJECTS_REQUESTED';
export const STAR_PROJECTS_LOADED = 'STAR_PROJECTS_LOADED';

export const USER_CONTRACTS_REQUESTED = 'USER_CONTRACTS_REQUESTED';
export const USER_CONTRACTS_LOADED = 'USER_CONTRACTS_LOADED';

export const PROJECT_STATISTICS_REQUESTED = 'PROJECT_STATISTICS_REQUESTED';
export const PROJECT_STATISTICS_LOADED = 'PROJECT_STATISTICS_LOADED';

export const DOWNLOAD_DOCUMENTS_REQUESTED = 'DOWNLOAD_DOCUMENTS_REQUESTED';
export const DOCUMENTS_LOADED = 'DOCUMENTS_LOADED';

export const SHORT_USERS_INFO_REQUESTED = 'SHORT_USERS_INFO_REQUESTED';
export const SHORT_USERS_INFO_LOADED = 'SHORT_USERS_INFO_LOADED';

export const SPEAKER_INFO_REQUESTED = 'SPEAKER_INFO_REQUESTED';
export const SPEAKER_INFO_LOADED = 'SPEAKER_INFO_LOADED';

export const MESSAGES_REQUESTED = 'MESSAGES_REQUESTED';
export const MESSAGES_LOADED = 'MESSAGES_LOADED';

export const COMMENT_REPLY_SENT = 'COMMENT_REPLY_SENT';
export const COMMENT_REPLY_ACCEPTED = 'COMMENT_REPLY_ACCEPTED';

export const USER_UDR_CONVERT_REQUEST = 'USER_UDR_CONVERT_REQUEST';
export const USER_UDR_CONVERTED = 'USER_UDR_CONVERTED';

export const REFUSE_TRANSACTION_SENT = 'REFUSE_TRANSACTION_SENT';
export const REFUSE_TRANSACTION_ACCEPTED = 'REFUSE_TRANSACTION_ACCEPTED';

export const INVEST_AMOUNT_CHANGED = 'INVEST_AMOUNT_CHANGED';
export const RESET_TIMER = 'RESET_TIMER';

export const SMS_SING_REQUEST = 'SMS_SING_REQUEST';
export const INVEST_DOC_SINGED = 'INVEST_DOC_SINGED';
export const ERROR_CODE = 'ERROR_CODE';

export const SMS_RESEND_REQUEST = 'SMS_RESEND_REQUEST';

export const RESERVATION_REQUESTED = 'RESERVATION_REQUESTED';
export const RESERVATION_ERROR = 'RESERVATION_ERROR';
export const SMS_SIGN = 'SMS_SIGN';

export const GENERATE_PAYMENT_PLUGIN_REQUEST = 'GENERATE_PAYMENT_PLUGIN_REQUEST';
export const PAYMENT_PLUGIN_LINK_ACQUIRED = 'PAYMENT_PLUGIN_LINK_ACQUIRED';

export const INVEST_REQUESTED = 'INVEST_REQUESTED';
export const PAYMENT_BUCKET_RECEIVED = 'PAYMENT_BUCKET_RECEIVED';

export const USER_HISTORY_REQUEST = 'USER_HISTORY_REQUEST';
export const USER_HISTORY_LOADED = 'USER_HISTORY_LOADED';

export const DOCUMENTS_SING_REQUESTED = 'DOCUMENTS_SING_REQUESTED';
export const DOCUMENTS_SINGED = 'DOCUMENTS_SINGED';

export const SMS_GENERATE_REQUESTED = 'SMS_GENERATE_REQUESTED';
export const SMS_GENERATED = 'SMS_GENERATED';

export const CANCEL_BUCKET_REQUEST = 'CANCEL_BUCKET_REQUEST';
export const CANCEL_BUCKET_SUCCESS = 'CANCEL_BUCKET_SUCCESS';
export const CANCEL_BUCKET_FAILED = 'CANCEL_BUCKET_FAILED';

export const TEMPLATE_REQUESTED = 'TEMPLATE_REQUESTED';
export const TEMPLATE_GENERATED = 'TEMPLATE_GENERATED';

export const POSTED_DATE_SENT = 'POSTED_DATE_SENT';
export const POSTED_DATE_SAVED = 'POSTED_DATE_SAVED';

export const STAR_PROJECT_ID_SAVED = 'STAR_PROJECT_ID_SAVED';

export const WITHDRAWAL_DATA_REQUESTED = 'WITHDRAWAL_DATA_REQUESTED';
export const WITHDRAWAL_DATA_LOADED = 'WITHDRAWAL_DATA_LOADED';
export const INVOICE_DETAILS_SEND_REQUESTED = 'INVOICE_DETAILS_SEND_REQUESTED';
export const INVOICE_DETAILS_SEND_SUCCESS = 'INVOICE_DETAILS_SEND_SUCCESS';

export const SINGERS_REGISTER_FORMATION_REQUESTED = 'SINGERS_REGISTER_FORMATION_REQUESTED';
export const SINGERS_REGISTER_FORMED = 'SINGERS_REGISTER_FORMED';

export const SINGERS_REGISTER_STATUS_REQUESTED = 'SINGERS_REGISTER_STATUS_REQUESTED';
export const SINGERS_REGISTER_STATUS_RECEIVED = 'SINGERS_REGISTER_STATUS_RECEIVED';

export const SINGERS_REGISTER_REQUESTED = 'SINGERS_REGISTER_REQUESTED';
export const SINGERS_REGISTER_LOADED = 'SINGERS_REGISTER_LOADED';
export const SINGERS_REGISTER_FAILED = 'SINGERS_REGISTER_FAILED';

export const INVEST_AMOUNT_SAVE_REQUESTED = 'INVEST_AMOUNT_SAVE_REQUESTED';
export const INVEST_AMOUNT_SAVED = 'INVEST_AMOUNT_SAVED';

export const GENERAL_INFORMATION_REQUESTED = 'GENERAL_INFORMATION_REQUESTED';
export const GENERAL_INFORMATION = 'GENERAL_INFORMATION';
