import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* formationSingersRegister({ projectId }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/documents/signed_doc_registry',
        data: {
          project_uuid: projectId,
        },
      });

    yield put({ type: actionTypes.SINGERS_REGISTER_FORMED, response });
    if (response?.task_id) {
      yield put({
        type: actionTypes.SINGERS_REGISTER_STATUS_REQUESTED,
        taskId: response.task_id,
      });
    }
  } catch ({ errors }) {
    yield put({ type: actionTypes.ERROR_OCCURRED, errors });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SINGERS_REGISTER_FORMATION_REQUESTED, formationSingersRegister);
}
