import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* leaveCommentSaga({
  text,
}) {
  try {
    yield call(request,
      {
        method: 'POST',
        pathname: '/messages',
        data: {
          text,
        },
      });

    yield put({ type: actionTypes.COMMENT_REPLY_ACCEPTED });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.COMMENT_REPLY_SENT, leaveCommentSaga);
}
