import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getAvailableInvestments({ projectId }) {
  try {
    const { data: response } = yield call(request,
      {
        pathname: '/investments/projects',
        query: `?project_uuid=${projectId}`,
      });

    yield put({ type: actionTypes.AVAILABLE_INVESTMENTS_LOADED, response });

    // if (response[0]?.udr_list || response[0]?.project_type === 'loan') {
    //   yield put({ type: actionTypes.INVESTMENT_MODAL_TOGGLED, value: true });
    // } else {
    //   yield put({
    //     type: actionTypes.INFO_MODAL_OPEN,
    //     messageTitle: 'Сбор временно приостановлен',
    //     message: 'Производится расчет результатов по проекту',
    //   });
    // }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.AVAILABLE_INVESTMENTS_REQUESTED, getAvailableInvestments);
}
