import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getWithdrawalDataSaga({ projectId }) {
  try {
    const { data: response } = yield call(request,
      {
        pathname: `/payments/project/${projectId}/refund_info`,
      });

    const withdrawalData = { ...response, projectId };
    yield put({ type: actionTypes.WITHDRAWAL_DATA_LOADED, withdrawalData });

    yield put({
      type: actionTypes.WITHDRAWAL_MODAL_TOGGLE,
      value: true,
    });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.WITHDRAWAL_DATA_REQUESTED, getWithdrawalDataSaga);
}
