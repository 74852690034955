import {
  takeEvery,
  call,
  put,
  // select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

// const idSelector = ((state) => state.projects.currentProjectId);

// eslint-disable-next-line no-unused-vars
function* cancelBucketSaga({ bucketId, cancelStatus }) {
  try {
    yield call(request,
      {
        method: 'POST',
        pathname: '/payments/cancel',
        data: {
          bucket_uuid: bucketId,
        },
      });

    // if (cancelStatus === 'PAYMENT' || cancelStatus === 'WAITING_PAYMENT') {
    //   const projectId = yield select(idSelector);
    //   yield put({ type: actionTypes.INVEST_REQUESTED, projectId });
    // }

    yield put({ type: actionTypes.CANCEL_BUCKET_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.CANCEL_BUCKET_FAILED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.CANCEL_BUCKET_REQUEST, cancelBucketSaga);
}
