import axios from 'axios';

import { SERVER_ADDRESS } from '../config';
import tokenVerification from './tokenVerification';

const request = ({
  url = SERVER_ADDRESS,
  pathname = '/',
  method = 'GET',
  responseType = 'json',
  query = '',
  headers = {},
  data,
}) => new Promise(
  (resolve, reject) => {
    const token = localStorage.getItem('token');

    const options = {
      url: url + pathname + query,
      method,
      responseType,
      headers: {
        ...headers,
        'X-User-Agent': 'MtsStars:Web:version1',
        'X-Authorization': token || '',
      },
      credentials: 'include',
    };
    if (data) { options.data = data; }
    axios(options)
      .then((response) => {
        tokenVerification(response.headers['x-authorization']);
        return response.data ? response.data : response.status;
      })
      .then((rdata) => {
        if (!rdata.errors) resolve(rdata);
        reject(Array.isArray(rdata.errors) ? rdata.errors[0] : rdata.errors);
      })
      .catch((e) => {
        if (e && e.response && e.response.data) {
          reject(e.response.data.info || e.response.data);
        }
        reject(e);
      });
  },
);

export default request;
