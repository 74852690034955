import {
  all,
  call,
} from 'redux-saga/effects';

import cancelBucketSaga from './cancelBucketSaga';
import createInvestSaga from './createInvestSaga';
import createSMSsignature from './createSMSsignature';
import generatePaymentPluginLink from './generatePaymentPluginLink';
import getWithdrawalDataSaga from './getWithdrawalDataSaga';
import investmentReservation from './investmentReservation';
import resendSms from './resendSms';
import sendInvoiceDetails from './sendInvoiceDetails';

export default function* formSaga() {
  yield all([
    call(cancelBucketSaga),
    call(createInvestSaga),
    call(createSMSsignature),
    call(generatePaymentPluginLink),
    call(getWithdrawalDataSaga),
    call(investmentReservation),
    call(resendSms),
    call(sendInvoiceDetails),
  ]);
}
