import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';
import downloadFile from '../../../helpers/downloadFile';

function* getSingersRegister({ path }) {
  try {
    const response = yield call(request,
      {
        pathname: `/documents/static/${path}`,
        responseType: 'blob',
      });

    downloadFile(response, 'Реестр', 'csv');

    yield put({ type: actionTypes.SINGERS_REGISTER_LOADED, response });
  } catch ({ errors }) {
    const error = Object.values(errors)[0];
    if (error[0]) {
      yield put({ type: actionTypes.SINGERS_REGISTER_FAILED });
    }
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SINGERS_REGISTER_REQUESTED, getSingersRegister);
}
