import {
  all,
  call,
} from 'redux-saga/effects';

import getCurrentUser from './getCurrentUser';
import getShortUserInfo from './getShortUserInfo';
import getUserDocuments from './getUserDocuments';
import convertUserUDRforProject from './convertUserUDRforProject';
import getUserHistory from './getUserHistory';
import postRefuseTransaction from './postRefuseTransaction';

export default function* formSaga() {
  yield all([
    call(convertUserUDRforProject),
    call(getCurrentUser),
    call(getShortUserInfo),
    call(getUserDocuments),
    call(getUserHistory),
    call(postRefuseTransaction),
  ]);
}
