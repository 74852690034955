const SERVER_MESSAGES = {
  fileNotFound: 'FILE_NOT_FOUND',
  missingDataOrInvalid: 'MISSING_DATA_OR_DATA_IS_NOT_VALID',
  ok: 'OK',
  internalServerError: 'INTERNAL_SERVER_ERROR',
  accessDenied: 'ACCESS_DENIED',
  missingToken: 'MISSING_TOKEN',
  expiredToken: 'TOKEN_EXPIRED',
  notFound: 'NOT_FOUND',
  tooManyRequests: 'TOO_MANY_REQUESTS',
  wrongPassword: 'WRONG_PASSWORD',
  userNotFound: 'USER_NOT_FOUND',
  invalidDate: 'INVALID_DATE',
};

const ERROR_MESSAGES = {
  wrongPassword: 'Неверный пароль',
  userNotFound: 'Пользователь не найден',
};

const {
  REACT_APP_SERVER_ADDRESS: SERVER_ADDRESS,
  REACT_APP_PROXY_ROUTE: PROXY_ROUTE,
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_AWS_S3_BASE_URL: AWS_S3_BASE_URL,
  REACT_APP_ENV: ENV,
} = process.env;

export {
  SERVER_ADDRESS,
  PROXY_ROUTE,
  SERVER_MESSAGES,
  ERROR_MESSAGES,
  BASE_URL,
  AWS_S3_BASE_URL,
  ENV,
};
