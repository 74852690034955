import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

const projectSelector = ((state) => state.projects.currentProject);

function* postRefuseTransaction({ transactionsList }) {
  try {
    yield put({
      type: actionTypes.INFO_MODAL_OPEN,
      messageTitle: 'Ожидайте завершения операции',
      infoModalLoader: true,
    });

    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/transactions/reversal',
        data: { transactions: transactionsList },
      });

    if (response.success) {
      const project = yield select(projectSelector);
      yield put({ type: actionTypes.DISCLAIMER_MODAL_TOGGLE, value: true });
      yield put({ type: actionTypes.USER_INVESTMENTS_REQUESTED, projectsIdList: project.id });
    }

    yield put({ type: actionTypes.REFUSE_TRANSACTION_ACCEPTED, response });
    yield put({
      type: actionTypes.INFO_MODAL_CLOSED,
    });
  } catch ({ errors }) {
    yield put({
      type: actionTypes.INFO_MODAL_CLOSED,
    });
    yield put({
      type: actionTypes.INFO_MODAL_OPEN,
      messageTitle: 'Что-то пошло не так. Повторите попытку позже',
      message: errors ? `Ошибка: ${errors?.[0].description}` : '',
    });
    yield put({ type: actionTypes.ERROR_OCCURRED, errors });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.REFUSE_TRANSACTION_SENT, postRefuseTransaction);
}
