import {
  all,
  call,
} from 'redux-saga/effects';

import getMessages from './getMessages';
import leaveComment from './leaveCommentSaga';

export default function* formSaga() {
  yield all([
    call(getMessages),
    call(leaveComment),
  ]);
}
