import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getStarProjects({ id }) {
  try {
    const { data: response } = yield call(request,
      {
        pathname: '/projects',
        query: `?creators_uuid=${id}&status=active&?&sort_by=finished_at&order_by=desc`,
      });

    yield put({ type: actionTypes.STAR_PROJECTS_LOADED, response });
    const firstProjectUuid = response?.projects[0]?.uuid;
    if (firstProjectUuid) {
      yield put({ type: actionTypes.PROJECT_BY_ID_REQUESTED, id: firstProjectUuid });
    }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.STAR_PROJECTS_REQUESTED, getStarProjects);
}
