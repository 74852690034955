import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import history from '../../../history';
import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getProjectById({ id }) {
  try {
    const { data: response } = yield call(request,
      { pathname: `/projects/${id}` });

    yield put({ type: actionTypes.CURRENT_PROJECT_LOADED, response });
  } catch ({ errors }) {
    if (errors[0].description === 'project not found') {
      history.push('/error');
    }
    if (errors[0].description === 'permission denied') {
      history.push('/error');
    }
    yield put({ type: actionTypes.ERROR_OCCURRED, errors });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.PROJECT_BY_ID_REQUESTED, getProjectById);
}
