const downloadFile = (
  blob,
  name,
  extension,
) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.${extension}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default downloadFile;
