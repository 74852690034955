import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

const sumInProject = 'project limit exceed';
const sumInPlatform = 'contribution sum is more then  600000 RUB';

function* reservationSaga({ reserve }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/payments/reserve_udr',
        data: reserve,
      });

    if (response.status === 'PAYMENT') {
      yield put({
        type: actionTypes.GENERATE_PAYMENT_PLUGIN_REQUEST,
        bucketId: response.bucket_uuid,
      });
      yield put({
        type: actionTypes.INFO_MODAL_CLOSED,
      });
    } else {
      yield put({
        type: actionTypes.INFO_MODAL_OPEN,
        messageTitle: 'Что-то пошло не так. Повторите попытку позже',
      });
      yield put({
        type: actionTypes.CANCEL_BUCKET_REQUEST,
        bucketId: reserve.bucket_uuid,
      });
    }
  } catch ({ errors }) {
    yield put({
      type: actionTypes.INFO_MODAL_CLOSED,
    });
    if (!errors) {
      yield put({
        type: actionTypes.INFO_MODAL_OPEN,
        messageTitle: 'Что-то пошло не так. Повторите попытку позже',
      });
      yield put({
        type: actionTypes.CANCEL_BUCKET_REQUEST,
        bucketId: reserve.bucket_uuid,
      });
      return;
    }
    const { description } = errors?.[0] || '';

    if (description === sumInProject || description === sumInPlatform) {
      yield put({
        type: actionTypes.INFO_MODAL_OPEN,
        messageTitle: 'Невозможно провести оплату',
        message: 'Сумма вашего платежа превышает максимальный возможный размер в 100 000 рублей, установленный Правилами платформы',
      });
      return;
    }
    if (description === 'not enough free udr for reservation') {
      yield put({
        type: actionTypes.INFO_MODAL_OPEN,
        messageTitle: 'Невозможно провести оплату',
        message: 'Предложение закончилось...',
      });
      return;
    }

    yield put({
      type: actionTypes.INFO_MODAL_OPEN,
      messageTitle: 'Что-то пошло не так. Повторите попытку позже',
    });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.RESERVATION_REQUESTED, reservationSaga);
}
