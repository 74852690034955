const changeUDRFormat = (
  udrList,
) => {
  const newUDRFormat = (udr) => ({
    conversionStartDate: udr.conversion_start_date,
    description: udr.description,
    conversionEndDate: udr.conversion_end_date,
    udrId: udr.udr_uuid,
    projectId: udr.project_uuid,
    investmentNumber: udr.investment_number || 'ASTR5749',
    group: udr.group,
    price: udr.price / 100,
    paymentDate: udr.payment_date,
    udr: udr.udr,
    status: udr.status,
    transaction: udr.transaction,
    type: udr.type,
  });

  if (Array.isArray(udrList)) {
    const newUDRList = udrList.map((udr) => newUDRFormat(udr));

    return newUDRList;
  }

  return newUDRFormat(udrList);
};

export default changeUDRFormat;
