import {
  all,
  call,
} from 'redux-saga/effects';

import getAvailableInvestments from './getAvailableInvestments';
import getOneOfProjects from './getOneOfProjects';
import getProjects from './getProjects';
import getProjectById from './getProjectById';
import getRecentProjects from './getRecentProjects';
import getUserInvestments from './getUserInvestments';
import getUserProjects from './getUserProjects';
import getSpeakerProjects from './getSpeakerProjects';

export default function* formSaga() {
  yield all([
    call(getProjects),
    call(getProjectById),
    call(getUserProjects),
    call(getSpeakerProjects),
    call(getOneOfProjects),
    call(getRecentProjects),
    call(getUserInvestments),
    call(getAvailableInvestments),
  ]);
}
