import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* documentSignSaga({ documentsList, code }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/documents/sign',
        data: {
          document_uuids: documentsList,
          sms: code,
        },
      });

    yield put({ type: actionTypes.DOCUMENTS_SINGED, response });
    yield put({ type: actionTypes.SMS_MODAL_TOGGLED, value: false });
    yield put({ type: actionTypes.CURRENT_USER_REQUESTED });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_CODE, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.DOCUMENTS_SING_REQUESTED, documentSignSaga);
}
