import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

const initialState = {
  totalSum: 0,
  collectedSum: 0,
  percent: 0,
  socialLinks: [],
  show: false,
};

function general(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.SOCIAL_LINKS_LOADED:
      return {
        ...state,
        socialLinks: action.response,
      };
    case actionTypes.GENERAL_INFORMATION: {
      const {
        response: { goal: totalSumRaw, current_collection_amount: collectedSumRaw, show },
      } = action;
      const totalSum = totalSumRaw / 100;
      const collectedSum = collectedSumRaw / 100;
      const percent = (collectedSum / totalSum) * 100;

      return {
        ...state,
        totalSum,
        collectedSum,
        percent: percent >= 100 ? 100 : percent,
        show,
      };
    }
    default:
      return state;
  }
}

export default general;
