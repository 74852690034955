import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

import changeMessageFormat from '../../helpers/changeMessageFormat';

const initialState = {
  comments: [],
  loading: false,
  commentsCount: 0,
  expiryTimestamp: null,
  timerIsVisible: false,
};

function forum(state = initialState, action) {
  const { response, offset } = action;

  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.MESSAGES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MESSAGES_LOADED:
      return {
        ...state,
        comments: offset > 0
          ? [...new Set([...state.comments, ...changeMessageFormat(response.messages)])]
          : [...changeMessageFormat(response.messages)],
        commentsCount: response.overall_count,
        loading: false,
      };
    case actionTypes.COMMENT_REPLY_ACCEPTED:
      return {
        ...state,
        expiryTimestamp: new Date().setSeconds(new Date().getSeconds() + 30),
        timerIsVisible: true,
        comments: initialState.comments,
      };
    case actionTypes.RESET_TIMER:
      return {
        ...state,
        expiryTimestamp: null,
        timerIsVisible: false,
      };
    case actionTypes.ERROR_OCCURRED:
      return {
        ...state,
        loading: false,
        comments: [...state.comments],
      };

    default:
      return state;
  }
}

export default forum;
