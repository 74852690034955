import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

import changeDocumentsFormat from '../../helpers/changeDocumentsFormat';

const initialState = {
  documentsList: [],
  loading: false,
  politics: '',
  rules: '',
  userContracts: [],
  registerStatus: '',
  taskId: '',
  textError: '',
  register: {},
};

function documents(state = initialState, action) {
  const {
    response, documentType, documentsList,
  } = action;

  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.TEMPLATE_GENERATED:
      return {
        ...state,
        [documentType]: response.signature_uuid,
      };
    case actionTypes.USER_CONTRACTS_LOADED:
      return {
        ...state,
        loading: false,
        userContracts: changeDocumentsFormat(response.documents).filter(({ status }) => status === 'SIGNED' || status === 'SIGNED_AND_PAYED'),
      };
    case actionTypes.USER_CONTRACTS_REQUESTED:
      return {
        ...state,
        loading: true,
        documentsList,
      };
    case actionTypes.SMS_GENERATE_REQUESTED:
      return {
        ...state,
        documentsList,
      };
    case actionTypes.SINGERS_REGISTER_FORMED:
      return {
        ...state,
        taskId: response.task_id,
      };
    case actionTypes.SINGERS_REGISTER_LOADED:
      return {
        ...state,
        registerStatus: 'success',
        register: response,
      };
    case actionTypes.SINGERS_REGISTER_FAILED:
      return {
        ...state,
        registerStatus: 'failed',
      };
    case actionTypes.SINGERS_REGISTER_STATUS_RECEIVED:
      return {
        ...state,
        registerStatus: response.status,
        textError: response.error,
      };
    case actionTypes.PROJECT_BY_ID_REQUESTED:
      return {
        ...state,
        registerStatus: initialState.registerStatus,
        textError: initialState.textError,
      };

    default:
      return state;
  }
}

export default documents;
