import { takeLeading, call, put } from 'redux-saga/effects';

import history from '../../../history';
import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';
import { SERVER_ADDRESS } from '../../../config';

const url = `https://login.mts.ru/amserver/UI/Logout?goto=${SERVER_ADDRESS === 'https://crowdloud.mts.ru/api/v1' ? 'https://crowdloud.mts.ru' : 'https://stars-devel.mts.ru'}`;

function* logoutSaga() {
  try {
    yield call(request, {
      method: 'POST',
      pathname: '/logout',
    });

    yield put({ type: actionTypes.LOGOUT_SUCCESS });
    localStorage.removeItem('token');
    document.location.href = url;

    history.push('/');
  } catch (e) {
    yield put({ type: actionTypes.LOGOUT_ERROR, e });
  }
}

export default function* watcherSaga() {
  yield takeLeading(actionTypes.LOGOUT_REQUESTED, logoutSaga);
}
