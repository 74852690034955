import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

const initialState = {
  agreementsModalIsOpen: false,
  agreementsModalType: 'invest',
  attentionModalIsOpen: false,
  attentionModalType: '',
  detailsModalIsOpen: false,
  infoModalIsOpen: false,
  investModalIsOpen: false,
  investWithdrawalModalIsOpen: false,
  galleryModalIsOpen: false,
  galleryType: '',
  loadFileModalIsOpen: false,
  mobileMenuIsOpen: false,
  mobileMenuType: '',
  paymentModalIsOpen: false,
  refuseInfoModalIsOpen: false,
  topUpModalIsOpen: false,
  toggleModalContacts: false,
  smsModalType: '',
  paymentResultModalIsOpen: false,
  message: '',
  messageTitle: '',
  smsModalIsOpen: false,
  userUDRModalIsOpen: false,
  userRefuseModalIsOpen: false,
  infoModalLoader: false,
};

function appState(state = initialState, action) {
  const {
    attentionModalType,
    galleryType,
    message,
    messageTitle,
    modalType,
    payload,
    value,
    infoModalLoader,
  } = action;
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.INVESTMENT_MODAL_TOGGLED:
      return {
        ...state,
        investModalIsOpen: value,
        mobileMenuIsOpen: false,
      };
    case actionTypes.AGREEMENTS_MODAL_TOGGLED:
      return {
        ...state,
        agreementsModalIsOpen: value,
        agreementsModalType: modalType,
      };
    case actionTypes.INVEST_REQUESTED:
      return {
        ...state,
        agreementsModalType: initialState.agreementsModalType,
      };
    case actionTypes.MOBILE_MENU_TOGGLED:
      return {
        ...state,
        mobileMenuIsOpen: payload.value,
        mobileMenuType: payload.type,
      };
    case actionTypes.PAYMENT_MODAL_TOGGLED:
      return {
        ...state,
        paymentModalIsOpen: value,
        investModalIsOpen: false,
      };
    case actionTypes.INFO_MODAL_OPEN:
      return {
        ...state,
        infoModalIsOpen: true,
        messageTitle,
        message,
        infoModalLoader,
      };
    case actionTypes.INFO_MODAL_CLOSED:
      return {
        ...state,
        infoModalIsOpen: false,
      };

    case actionTypes.REFUSE_TRANSACTION_ACCEPTED:
      return {
        ...state,
        transactionInfoModalIsOpen: true,
      };
    case actionTypes.DISCLAIMER_MODAL_TOGGLE:
      return {
        ...state,
        disclaimerModalIsOpen: value,
      };
    case actionTypes.ATTENTION_MODAL_TOGGLED:
      return {
        ...state,
        attentionModalIsOpen: value,
        investModalIsOpen: false,
        attentionModalType,
      };
    case actionTypes.UDR_MODAL_TOGGLE:
      return {
        ...state,
        userUDRModalIsOpen: value,
      };
    case actionTypes.REFUSE_MODAL_TOGGLE:
      return {
        ...state,
        userRefuseModalIsOpen: value,
      };
    case actionTypes.TOP_UP_MODAL_TOGGLE:
      return {
        ...state,
        topUpModalIsOpen: value,
      };
    case actionTypes.SMS_MODAL_TOGGLED:
      return {
        ...state,
        smsModalIsOpen: value,
        smsModalType: modalType,
        attentionModalIsOpen: false,
      };
    case actionTypes.CANCEL_BUCKET_SUCCESS:
    case actionTypes.CANCEL_BUCKET_FAILED:
      return {
        ...state,
        attentionModalIsOpen: false,
        smsModalIsOpen: false,
      };
    case actionTypes.MODAL_CONTACT_TOGGLE:
      return {
        ...state,
        toggleModalContacts: value,
      };
    case actionTypes.PAYMENT_RESULT_MODAL_TOGGLED:
      return {
        ...state,
        paymentResultModalIsOpen: value,
      };
    case actionTypes.PAYMENT_DETAILS_MODAL_TOGGLED:
      return {
        ...state,
        detailsModalIsOpen: value,
      };
    case actionTypes.GALLERY_MODAL_TOGGLED:
      return {
        ...state,
        galleryModalIsOpen: value,
        galleryType,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        mobileMenuIsOpen: false,
        mobileMenuType: '',
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        // agreementsModalIsOpen: true,
      };
    case actionTypes.SMS_SIGN:
      return {
        ...state,
        smsModalIsOpen: true,
        smsModalType: 'payment',
      };
    case actionTypes.LOAD_FILE_MODAL_TOGGLE:
      return {
        ...state,
        loadFileModalIsOpen: value,
      };
    case actionTypes.WITHDRAWAL_MODAL_TOGGLE:
      return {
        ...state,
        investWithdrawalModalIsOpen: value,
      };
    case actionTypes.RESERVATION_ERROR:
      return {
        ...state,
        investModalIsOpen: false,
        infoModalIsOpen: false,
      };
    case actionTypes.ERROR_OCCURRED:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default appState;
