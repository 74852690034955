const changeDocumentsFormat = (
  documents,
) => (documents.map((item) => ({
  signingDate: item.signing_date,
  status: item.status,
  name: item.name,
  templateUuid: item.template_uuid,
  text: item.text,
  type: item.type,
  userId: item.user_uuid,
  id: item.uuid,
})));

export default changeDocumentsFormat;
