import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getUserInvestmentsSaga({ projectsIdList }) {
  try {
    const currentQuery = Array.isArray(projectsIdList)
      ? projectsIdList.map((id) => `&project_uuid=${id}`)
      : `project_uuid=${projectsIdList}`;

    const query = Array.isArray(projectsIdList)
      ? `${currentQuery.join('')}`
      : currentQuery;

    const { data: response } = yield call(request,
      {
        pathname: '/investments/user?',
        query,
      });

    yield put({ type: actionTypes.USER_INVESTMENTS_LOADED, response });
  } catch (error) {
    yield put({ type: actionTypes.USER_INVESTMENTS_ERROR, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.USER_INVESTMENTS_REQUESTED, getUserInvestmentsSaga);
}
