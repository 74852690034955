const changeProjectFormat = (
  projects,
) => {
  const newCurrentProject = (project) => ({
    authorDescription: project.author_description,
    backgroundImageUrl: project.background_image_url,
    collectionAmount: Math.ceil(project.current_collection_amount / 100),
    collectionDateEnd: project.collection_date_end,
    description: project.showcase_description,
    expirationAt: project.finished_at,
    projectStartDate: project.started_at,
    projectCreatedDate: project.created_at,
    fullDescription: project.full_description,
    id: project.uuid,
    investmentType: project.type,
    loanTerm: project.reservation_days,
    mediaReferences: project.media_references,
    minimalGoal: project.minimal_goal / 100,
    maxGoal: project.max_goal / 100,
    name: project.name,
    percent: Math.ceil((project.current_collection_amount / project.goal) * 100),
    projectGoal: Math.ceil(project.goal / 100),
    projectStatus: project.status,
    refundStatus: project.refund_status,
    speakerId: project.creator_uuid,
    shortDescription: project.short_description,
    shortAuthorDescription: project.short_author_description,
    stepDescription: project.step_description,
    speakerName: project.star_name,
    speakerAvatar: project.star_avatar_url,
    rate: project.percent,
    subtitle: project.promo_subtitle,
  });

  if (Array.isArray(projects)) {
    const activeProjectsList = projects.filter((project) => project.status === 'active');
    const notActiveProjectsList = projects.filter((project) => project.status !== 'active');
    return [...activeProjectsList, ...notActiveProjectsList].map(
      (project) => newCurrentProject(project),
    );
  }

  return newCurrentProject(projects);
};

export default changeProjectFormat;
