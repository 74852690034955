import { noun } from 'plural-ru';
import moment from 'moment';

import { AWS_S3_BASE_URL } from '../config';

const today = new Date().setHours(0, 0, 0, 0);
const LIMIT_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 5;

const addThousandSeparator = (number, separator = ' ') => {
  const numberProxy = number || 0;
  return numberProxy.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator).replace(/\./g, ',');
};

const formattedAmountValue = (value) => {
  const REG = /^\d{1,6}(\.\d{1,2})?$/ig;

  const isValid = REG.test(Number(value));

  return isValid;
};

const calculateDate = (expirationAt, loanTerm, type) => {
  const finalDate = new Date(expirationAt);
  const count = type === 'alone' ? 0 : 3;
  finalDate.setDate(finalDate.getDate() + loanTerm + count);
  return finalDate;
};

const formatDateToString = (sourceDate) => {
  if (!sourceDate) {
    return '[нет даты]';
  }

  return `${moment(sourceDate, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')}`;
};

const formatDateWithTime = (sourceDate) => {
  if (!sourceDate) {
    return '[нет даты]';
  }

  const currentTimeZoneOffsetInHours = new Date(sourceDate).getTimezoneOffset() / 60;

  return `${moment(sourceDate, 'YYYY-MM-DDTHH:mm:ss')
    .subtract(currentTimeZoneOffsetInHours, 'hours')
    .format('DD.MM.YYYY, HH:mm')}`;
};

const lifespanCalculation = (transactionDate) => {
  if (!transactionDate) {
    return '[нет даты]';
  }
  const isAlive = Date.now() - new Date(transactionDate).getTime() < LIMIT_DAY_IN_MILLISECONDS;

  return isAlive;
};

const formatDateWithTimeToString = (sourceDate) => {
  if (!sourceDate) {
    return '[нет даты]';
  }

  const currentTimeZoneOffsetInHours = new Date(sourceDate).getTimezoneOffset() / 60;

  const date = new Date(sourceDate);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  // const hours = date.getHours();
  // const minutes = date.getMinutes();
  const fullTime = `${moment(sourceDate, 'YYYY-MM-DDTHH:mm:ss')
    .subtract(currentTimeZoneOffsetInHours, 'hours')
    .format('HH:mm')}`;

  if (date.setHours(0, 0, 0, 0) === today) {
    return `Сегодня, ${fullTime}`;
  }
  if (date.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)) {
    return `Вчера, ${fullTime}`;
  }
  return `${moment(sourceDate, 'YYYY-MM-DDTHH:mm:ss')
    .subtract(currentTimeZoneOffsetInHours, 'hours')
    .format('DD.MM.YYYY, HH:mm')}`;
};

const formatSecondsToString = (sec, colon) => {
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec - h * 3600) / 60);
  const s = Math.round(sec - h * 3600 - m * 60);
  return (colon ? `${m}:${(`0${s}`).slice(-2)}` : `${m} мин. ${s} сек.`);
};

const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) {
    return `${(bytes / 1073741824).toFixed(2)} GB`;
  } if (bytes >= 1048576) {
    return `${(bytes / 1048576).toFixed(2)} MB`;
  } if (bytes >= 900) {
    return `${Math.ceil((bytes / 1024).toFixed(1))} KB`;
  }
  return `${bytes} байт`;
};

const getDayPlural = (count) => `${count} ${noun(count, 'день', 'дня', 'дней')}`;

const countOfDays = (expirationAt) => Math.abs(moment(expirationAt).diff(moment(), 'days'));

const getBadgeText = (
  projectStatus,
  expirationAt,
  isMobile,
  collectionDateEnd,
  minimalGoal,
  collectionAmount,
  role,
  startDate,
) => {
  const days = countOfDays(expirationAt);
  const hours = moment(expirationAt).diff(moment(), 'hours');
  const badgeIsActive = (projectStatus === 'active' && !(days > 0)) || (projectStatus === 'declined');
  const previously = new Date(collectionDateEnd) < new Date(expirationAt);
  // const successfully = collectionAmount >= minimalGoal;

  const getHourPlural = (count) => `${count} ${noun(count, 'час', 'часа', 'часов')}`;
  // eslint-disable-next-line no-nested-ternary
  const interval = !badgeIsActive ? getDayPlural(days) : hours > 0 ? getHourPlural(hours) : 'менее часа';
  switch (projectStatus) {
    case 'finished': {
      if (collectionDateEnd && previously) {
        return { badgeText: 'Закрыт досрочно' };
      }
      return { badgeText: 'Завершен успешно' };
    }
    case 'pending': {
      return { badgeText: 'Модерация пройдена успешно' };
    }
    case 'chooseDate': {
      return { badgeText: `Будет опубликован ${startDate}` };
    }
    case 'declined': {
      if (role === 'LPI') {
        return { badgeText: 'Отклонён' };
      }
      return { badgeText: 'Отменён', badgeIsActive };
    }
    case 'active': {
      if (isMobile) {
        return { badgeText: `${interval}`, badgeIsActive };
      }
      return { badgeText: `До завершения ${interval}`, badgeIsActive };
    }
    default:
      return { badgeText: 'На модерации' };
  }
};

const getHexColorWithOpacity = (color, opacity) => {
  const MAX_OPACITY = 0xFF;
  const calculatedOpacity = Math.round(MAX_OPACITY * opacity);
  const hexOpacity = calculatedOpacity.toString(16);
  return color + hexOpacity;
};

const getFileDownloadLink = (key) => `${AWS_S3_BASE_URL}/${key}`;

const getExpectedIncome = (investmentAmount, rate, loanTerm) => {
  const thisYear = new Date().getFullYear();
  const isLeapYear = !(new Date(thisYear, 1, 29).getMonth() - 1);
  const days = isLeapYear ? 366 : 365;

  const expectedIncome = (investmentAmount * rate * (loanTerm / days)) / 100;

  return expectedIncome;
};

const getPaymentType = (isSuccess, hasError) => {
  if (isSuccess) {
    return 'success';
  }
  if (!isSuccess && hasError) {
    return 'warning';
  }
  return 'error';
};

const changePhoneNumberFormat = (phone) => {
  const match = phone.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
  return ['+7', ' (', match[1], ') ', match[2], '-', match[3], '-', match[4]].join('');
};

const formattedSmsPhone = (phone) => {
  const match = phone.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
  return ['+7', ' ', match[1], ' ', match[2], '-', match[3], '-', match[4]].join('');
};

const scrollElementIntoView = (element, offset = 0) => {
  const scrollTop = window.pageYOffset;
  const finalOffset = element.getBoundingClientRect().top + scrollTop + offset;

  window.parent.scrollTo({
    top: finalOffset,
    behavior: 'smooth',
  });
};

export {
  addThousandSeparator,
  calculateDate,
  changePhoneNumberFormat,
  countOfDays,
  formattedAmountValue,
  formattedSmsPhone,
  formatSizeUnits,
  formatDateToString,
  formatDateWithTime,
  formatDateWithTimeToString,
  formatSecondsToString,
  getBadgeText,
  getHexColorWithOpacity,
  getFileDownloadLink,
  getDayPlural,
  getExpectedIncome,
  getPaymentType,
  lifespanCalculation,
  scrollElementIntoView,
};
