import React, { Suspense } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import './fonts/MTSSans/stylesheet.css';
import './App.css';

import Main from './pages/MainPage';
import theme from './config/MUITheme';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <div className="App">
      <Suspense fallback={<div />}>
        <Main />
      </Suspense>
    </div>
  </MuiThemeProvider>
);

export default App;
