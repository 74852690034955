import {
  all,
  call,
} from 'redux-saga/effects';

import loginSaga from './loginSaga';
import logoutSaga from './logoutSaga';

export default function* formSaga() {
  yield all([
    call(loginSaga),
    call(logoutSaga),
  ]);
}
