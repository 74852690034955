const changeUserInvestmentsFormat = (
  investments,
) => {
  const newUserInvestments = (investment) => ({
    id: investment.project_uuid,
    sum: investment.sum / 100,
    investmentDate: investment.created_at || '2021-09-07T11:46:48Z',
    investProps: investment.loan_list || investment.udr_list,
  });

  if (Array.isArray(investments)) {
    const newUserInvestmentsList = investments.map((investment) => newUserInvestments(investment));

    return newUserInvestmentsList;
  }

  return newUserInvestments(investments);
};

export default changeUserInvestmentsFormat;
