const changeMessageFormat = (
  messages,
) => {
  const newCurrentMessage = (message) => ({
    id: message.msg_uuid,
    authorId: message.sender_uuid,
    text: message.text,
    commentDate: message.created_at,
  });

  if (Array.isArray(messages)) {
    const newMessagesList = messages.map((message) => newCurrentMessage(message));

    return newMessagesList;
  }

  return newCurrentMessage(messages);
};

export default changeMessageFormat;
