import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* createInvestSaga({ projectId, redirectPath, reserve }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/payments/bucket',
        data: {
          project_uuid: projectId,
        },
      });

    if (response.status === 'ESIA_AUTH') {
      yield put({
        type: actionTypes.ATTENTION_MODAL_TOGGLED,
        value: true,
        attentionModalType: 'esia',
        modalProps: response.meta,
      });
    }
    if (response.status === 'CHOOSING_INVEST') {
      yield put({
        type: actionTypes.INFO_MODAL_OPEN,
        messageTitle: 'Идёт подготовка к оплате.',
        infoModalLoader: true,
      });
      yield put({
        type: actionTypes.RESERVATION_REQUESTED,
        reserve: { bucket_uuid: response.bucket_uuid, reservation: reserve },
      });
      // yield put({ type: actionTypes.PAYMENT_BUCKET_RECEIVED, response });
      // yield put({
      //   type: actionTypes.GENERATE_PAYMENT_PLUGIN_REQUEST,
      //   bucketId: response.bucket_uuid,
      // });
      // yield put({ type: actionTypes.AVAILABLE_INVESTMENTS_REQUESTED, projectId });
    }
    if (redirectPath === 'repeat' && response.status === 'PAYMENT') {
      yield put({
        type: actionTypes.GENERATE_PAYMENT_PLUGIN_REQUEST,
        bucketId: response.bucket_uuid,
      });
    }
    if (redirectPath !== 'repeat' && (response.status === 'WAITING_PAYMENT'
    || response.status === 'PAYMENT'
    || response.status === 'SMS_SIGN')) {
      yield put({
        type: actionTypes.ATTENTION_MODAL_TOGGLED,
        value: true,
        attentionModalType: 'invest',
        modalProps: response,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.INVEST_REQUESTED, createInvestSaga);
}
