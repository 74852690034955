import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getUserHistorySaga({ transactionType }) {
  try {
    const currentPath = transactionType === 'all'
      ? '/transactions/user/history'
      : '/transactions/user/history?skiprefunded=true';

    const { data: response } = yield call(request,
      {
        pathname: currentPath,
      });

    yield put({ type: actionTypes.USER_HISTORY_LOADED, response });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.USER_HISTORY_REQUEST, getUserHistorySaga);
}
