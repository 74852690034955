import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* convertUserUDRSaga({ udrId }) {
  const data = {
    udr_uuids: udrId,
  };
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/investments/use_udr',
        data,
      });

    yield put({ type: actionTypes.USER_UDR_CONVERTED, response });
    yield put({ type: actionTypes.UDR_MODAL_TOGGLE, value: true });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.USER_UDR_CONVERT_REQUEST, convertUserUDRSaga);
}
