import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

const userSelector = ((state) => state.users.user);

function* getOneOfProjects({ id }) {
  try {
    const { data } = yield call(request,
      { pathname: '/projects' });

    const user = yield select(userSelector);
    const response = data.projects.find(({ uuid }) => uuid === id);

    if (!user && !response) {
      const redirectPath = `${window.location.href}`;
      yield put({ type: actionTypes.LOGIN_REQUESTED, pathname: redirectPath });
    }
    if (!user && response) {
      yield put({ type: actionTypes.CURRENT_PROJECT_LOADED, response });
    }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.ONE_OF_PROJECTS_REQUESTED, getOneOfProjects);
}
