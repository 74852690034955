import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';
import * as actionTypes from '../../actions/actionList';
import { saveGeneralInformation } from '../../actions/index';
import request from '../../../helpers/api';

function* getGeneralInformation() {
  try {
    const { data: response } = yield call(request, { pathname: '/projects/stats' });
    yield put(saveGeneralInformation(response));
  } catch (error) {
    // empty
  }
}

export default function* generalSaga() {
  yield takeEvery(actionTypes.GENERAL_INFORMATION_REQUESTED, getGeneralInformation);
}
