import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

import changeProjectFormat from '../../helpers/changeProjectFormat';
import changeUserInvestmentsFormat from '../../helpers/changeUserInvestmentsFormat';

const initialState = {
  currentProject: {},
  currentUDR: [],
  loading: false,
  udrLoading: false,
  projects: [],
  userInvestments: [],
  userProjects: [],
  speakerProjects: [],
  videoType: 'youTube',
  currentProjectId: '',
  userInvestmentsLoading: false,
  postedDate: '',
};

function projects(state = initialState, action) {
  const {
    response,
    status,
    value,
    postedDate,
    projectId,
  } = action;
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.PROJECTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PROJECTS_LOADED:
      return {
        ...state,
        projects: changeProjectFormat(response.projects),
        loading: false,
      };
    case actionTypes.RECENT_PROJECTS_LOADED:
      return {
        ...state,
        projects: changeProjectFormat(response.projects),
        loading: false,
      };
    case actionTypes.PROJECT_BY_ID_REQUESTED:
      return {
        ...state,
        currentProject: {},
        loading: true,
      };
    case actionTypes.CURRENT_PROJECT_LOADED:
      return {
        ...state,
        currentProject: changeProjectFormat(response),
        loading: false,
      };
    case actionTypes.AVAILABLE_INVESTMENTS_REQUESTED:
      return {
        ...state,
        udrLoading: true,
        currentUDR: initialState.currentUDR,
      };
    case actionTypes.AVAILABLE_INVESTMENTS_LOADED:
      return {
        ...state,
        udrLoading: false,
        currentUDR:
          response[0]?.udr_list
            ? response[0]?.udr_list
            : [],
      };
    case actionTypes.USER_PROJECTS_REQUESTED:
      return {
        ...state,
        loading: true,
        userProjects: [],
      };
    case actionTypes.USER_PROJECTS_LOADED:
      return {
        ...state,
        loading: false,
        userProjects: changeProjectFormat(response.projects, status),
      };
    case actionTypes.USER_INVESTMENTS_REQUESTED:
      return {
        ...state,
        userInvestmentsLoading: true,
        userInvestments: [],
      };
    case actionTypes.USER_INVESTMENTS_LOADED:
      return {
        ...state,
        userInvestmentsLoading: false,
        userInvestments: changeUserInvestmentsFormat(response),
      };
    case actionTypes.USER_INVESTMENTS_ERROR:
      return {
        ...state,
        userInvestmentsLoading: false,
        userInvestments: [],
      };
    case actionTypes.SPEAKER_PROJECTS_LOADED:
      return {
        ...state,
        loading: false,
        speakerProjects: changeProjectFormat(response.projects, status),
      };
    case actionTypes.UDR_MODAL_TOGGLE:
      return {
        ...state,
        currentProjectId: value ? action.id : '',
      };
    case actionTypes.POSTED_DATE_SENT:
      return {
        ...state,
        postedDate,
      };
    case actionTypes.STAR_PROJECT_ID_SAVED:
      return {
        ...state,
        currentProjectId: projectId,
      };
    case actionTypes.INVEST_REQUESTED:
      return {
        ...state,
        currentProjectId: projectId,
      };
    case actionTypes.ERROR_OCCURRED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default projects;
