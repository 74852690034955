import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* checkSingersRegisterStatus({ taskId }) {
  try {
    const { data: response } = yield call(request, { pathname: `/documents/signed_doc_registry/${taskId}` });

    if (response.status === 'failed' || response.status === 'processing') {
      yield put({ type: actionTypes.SINGERS_REGISTER_STATUS_RECEIVED, response });
    }
    if (response.status === 'success') {
      const { pathname } = new URL(response.link);
      const path = pathname.split('/').slice(-1)[0];
      yield put({ type: actionTypes.SINGERS_REGISTER_REQUESTED, path });
    }
  } catch ({ errors }) {
    yield put({ type: actionTypes.ERROR_OCCURRED, errors });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SINGERS_REGISTER_STATUS_REQUESTED, checkSingersRegisterStatus);
}
