const token = localStorage.getItem('token');

const tokenVerification = (
  xAuthorization,
) => {
  if (xAuthorization && xAuthorization !== token) {
    localStorage.setItem('token', xAuthorization);
  }
};

export default tokenVerification;
