import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';
import { getPaymentType } from '../../../helpers';
import { store } from '../../store';

const idSelector = ((state) => state.projects.currentProjectId);

function* generatePaymentPluginLink({ bucketId }) {
  try {
    const { data: response } = yield call(request,
      {
        method: 'POST',
        pathname: '/payments/pay',
        data: {
          bucket_uuid: bucketId,
        },
      });

    if (response?.meta?.payment_url) {
      yield put({
        type: actionTypes.SMS_MODAL_TOGGLED,
        value: false,
      });

      const { searchParams } = new URL(response.meta.payment_url);
      const sessionId = searchParams.get('sessionId');
      const tokenId = searchParams.get('tokenId');
      const projectId = yield select(idSelector);

      const widgetResultHandler = ({ eventName, message }) => {
        const isSuccess = eventName === 'successPayment';
        const paymentType = getPaymentType(isSuccess, !!message);
        if (eventName !== 'close') {
          store.dispatch({
            type: actionTypes.PAYMENT_RESULT_MODAL_TOGGLED,
            value: true,
            paymentType,
            message,
          });
        }
        if (isSuccess) {
          store.dispatch({
            type: actionTypes.USER_INVESTMENTS_REQUESTED,
            projectsIdList: projectId,
          });
          store.dispatch({
            type: actionTypes.AVAILABLE_INVESTMENTS_REQUESTED,
            projectId,
          });
        }
      };

      const params = {
        scenarioType: 'pay',
        environment: 'PROD',
        sessionId,
        tokenId,
        resultHandler: widgetResultHandler,
      };

      const widget = new window.MTSPay(params);
      widget.render('widget');

      yield put({
        type: actionTypes.PAYMENT_PLUGIN_LINK_ACQUIRED,
        response,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.ERROR_CODE, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.GENERATE_PAYMENT_PLUGIN_REQUEST, generatePaymentPluginLink);
}
