import { REHYDRATE } from 'redux-persist';

const projectStatistics = [
  {
    id: 1,
    date: new Date(),
    conversion: 0,
    views: 2,
    totalInvestors: 4,
    averageDaySum: 0,
    totalSum: 0,
    remainder: 2500000,
  },
  {
    id: 2,
    date: new Date(),
    conversion: 0,
    views: 20,
    totalInvestors: 4,
    averageDaySum: 25000,
    totalSum: 100000,
    remainder: 2400000,
  },
];

const totalStatistics = {
  averageConversion: 0,
  totalViews: 20,
  totalInvestors: 0,
  averageDaySum: 0,
};

const initialState = {
  projectStatistics,
  totalStatistics,
};

function statistics(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}

export default statistics;
