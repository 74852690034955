import { REHYDRATE } from 'redux-persist';

import * as actionTypes from '../actions/actionList';

import doc from '../../assets/files/Программа_2021.pdf';

import changeProjectFormat from '../../helpers/changeProjectFormat';

const fullSpeakerInfo = {
  reference: doc,
  metadata: {
    size: 2045,
  },
  name: 'Полная информация',
  type: 'file',
};

const initialState = {
  speakers: null,
  starProjects: [],
  fullSpeakerInfo,
};

function speakers(state = initialState, action) {
  const { response } = action;
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...initialState,
      };
    }
    case actionTypes.STAR_PROJECTS_REQUESTED:
      return {
        ...state,
        starProjects: initialState.starProjects,
      };
    case actionTypes.STAR_PROJECTS_LOADED:
      return {
        ...state,
        starProjects: changeProjectFormat(response.projects),
      };

    default:
      return state;
  }
}

export default speakers;
