import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

import downloadFile from '../../../helpers/downloadFile';

function* downloadDocumentsSaga({ id, name }) {
  try {
    const response = yield call(request,
      {
        pathname: `/documents/${id}/download`,
        responseType: 'blob',
      });
    downloadFile(response, name, 'pdf');
    yield put({ type: actionTypes.DOCUMENTS_LOADED, response });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.DOWNLOAD_DOCUMENTS_REQUESTED, downloadDocumentsSaga);
}
