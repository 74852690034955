import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* getShortUserInfoSaga({ messages }) {
  try {
    const idProjectsList = Object.values(messages).map((item) => item.sender_uuid);
    const uniqueList = [...new Set(idProjectsList)];

    const currentQuery = uniqueList.map((id) => `&user_uuid=${id}`);

    const { data: response } = yield call(request,
      {
        pathname: '/users/short_info',
        query: `?${currentQuery.join('')}`,
      });

    yield put({ type: actionTypes.SHORT_USERS_INFO_LOADED, response });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.SHORT_USERS_INFO_REQUESTED, getShortUserInfoSaga);
}
