import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

const userSelector = ((state) => state.users.user);

function* getUserProjectsSaga({ status }) {
  try {
    const currentStatus = status === 'active'
      ? `?status=${status}`
      : `?status=${status}&status=declined`;
    const { data: response } = yield call(request,
      {
        pathname: '/projects',
        query: `${currentStatus}&sort_by=finished_at&order_by=desc`,
      });

    const user = yield select(userSelector);
    if (response.projects && user?.role === 'Investor') {
      const { projects } = response;
      const projectsIdList = Object.values(projects).map(({ uuid }) => uuid);
      yield put({ type: actionTypes.USER_INVESTMENTS_REQUESTED, projectsIdList });
    }

    yield put({ type: actionTypes.USER_PROJECTS_LOADED, response, status });
  } catch (error) {
    yield put({ type: actionTypes.ERROR_OCCURRED, error });
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.USER_PROJECTS_REQUESTED, getUserProjectsSaga);
}
