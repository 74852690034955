import React, {
  lazy,
  memo,
  Suspense,
  useEffect,
} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import QueryParser from '../../hooks/QueryParser';

import useStyles from './styles';

import { getCurrentUser } from '../../redux/actions';

const token = localStorage.getItem('token') || '';

const AgreementsModal = lazy(() => import('../../components/AgreementsModal'));
const AttentionModal = lazy(() => import('../../components/AttentionModal'));
const DetailedProjectPage = lazy(() => import('../DetailedProjectPage'));
// const ForumPage = lazy(() => import('../ForumPage'));
const GalleryModal = lazy(() => import('../../components/GalleryModal'));
const TopUpModal = lazy(() => import('../../components/TopUpModal'));
const Header = lazy(() => import('../../components/Menu'));
const Footer = lazy(() => import('../../components/Footer'));
const InfoModal = lazy(() => import('../../components/InfoModal'));
const InvestmentModal = lazy(() => import('../../components/InvestmentModal'));
const LandingPage = lazy(() => import('../LandingPage'));
const MobileMenu = lazy(() => import('../../components/MobileMenu'));
const NoContent = lazy(() => import('../Error404'));
const ProjectsPage = lazy(() => import('../ProjectsPage'));
const SMSModal = lazy(() => import('../../components/SMSModal'));
const SpeakerPage = lazy(() => import('../SpeakerPage'));
const SupportPage = lazy(() => import('../SupportPage'));
const StarMenu = lazy(() => import('../UserPage/components/StarMenu'));
const UserPage = lazy(() => import('../UserPage'));
const UserUDRModal = lazy(() => import('../../components/UserUDRModal'));
const WithdrawalModal = lazy(() => import('../../components/WithdrawalModal'));

const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.login.isLogged);
  const agreementsModalIsOpen = useSelector((state) => state.appState.agreementsModalIsOpen);
  const mobileMenuIsOpen = useSelector((state) => state.appState.mobileMenuIsOpen);
  const galleryModalIsOpen = useSelector((state) => state.appState.galleryModalIsOpen);
  const topUpModalIsOpen = useSelector((state) => state.appState.topUpModalIsOpen);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser());
    }
  }, [token, isLogged]);

  useEffect(() => {
    document.body.style.overflow = mobileMenuIsOpen ? 'hidden' : 'auto';
  }, [mobileMenuIsOpen]);

  return (
    <div className={classes.mainContainer}>
      <Helmet>
        <title>Crowdloud</title>
        <meta property="og:url" content="https://crowdloud.mts.ru" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Crowdloud" />
        <meta property="og:description" content="Краудфандинговая платформа" />
      </Helmet>
      <Header />
      <Suspense fallback={<div className="min-height-wrapper" />}>
        <Switch>
          <Route exact path="/"><Redirect to="/projects" /></Route>
          <Route exact path={['/about']} component={LandingPage} />
          <Route exact path="/projects" component={ProjectsPage} />
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/speaker/:id" component={SpeakerPage} />
          <Route path="/lpi-project/:id" component={StarMenu} />
          {/* <Route exact path="/forum" component={ForumPage} /> */}
          <Route exact path="/project/:id" component={DetailedProjectPage} />
          <Route path="/profile" component={UserPage} />
          <Route path={['*', '/error']} component={NoContent} />
          <Route component={NoContent} />
        </Switch>
      </Suspense>
      <Footer />
      {mobileMenuIsOpen && <MobileMenu /> }
      {galleryModalIsOpen && <GalleryModal /> }
      {topUpModalIsOpen && <TopUpModal /> }
      <InvestmentModal />
      <InfoModal />
      <SMSModal />
      <AttentionModal />
      <div id="widget" />
      <QueryParser />
      <UserUDRModal />
      <WithdrawalModal />
      {agreementsModalIsOpen && !user && <AgreementsModal />}
    </div>
  );
};

export default memo(MainPage);
