import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';

import request from '../../../helpers/api';
import * as actionTypes from '../../actions/actionList';

function* saveInvestAmount({ projectId, value }) {
  try {
    const response = yield call(request,
      {
        method: 'PATCH',
        pathname: `/projects/${projectId}/current_collection_amount`,
        data: {
          current_collection_amount: Math.ceil(value * 100),
        },
      });

    if (response === 200) {
      yield put({ type: actionTypes.PROJECT_BY_ID_REQUESTED, id: projectId });
    }

    yield put({ type: actionTypes.INVEST_AMOUNT_SAVED });
  } catch ({ errors }) {
    if (errors[0]) {
      yield put({
        type: actionTypes.INFO_MODAL_OPEN,
        messageTitle: 'Что-то пошло не так...',
        message: `Ошибка: ${errors[0].description}`,
      });
    }
  }
}

export default function* watcherSaga() {
  yield takeEvery(actionTypes.INVEST_AMOUNT_SAVE_REQUESTED, saveInvestAmount);
}
